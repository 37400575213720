import type { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next'
import Head from 'next/head'
import Image from 'next/image'
import styles from '../styles/Home.module.css'
import variables from '../styles/variables.module.scss'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import axios from 'axios'
import { UserDetails } from '../common/interfaces/userDetails.interface'

const Home: NextPage = ({loaded, user}: InferGetServerSidePropsType<typeof getServerSideProps>) => {

  const router = useRouter()

  console.log(user)

  useEffect(() => {
    setTimeout(() => {
      router.push('/login')
    }, 200);
  }, [])

  return (
    <div className={styles.container}>
      Loading...
    </div>
  )
}

export default Home

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  let loaded = false;
  let user = {} as UserDetails;

  const { req, res } = ctx

  const { cookies } = req
  console.log(cookies);
  const headers = {
    authorization: cookies['authorization'],
  }

  axios.get('http://localhost:3000/api/user',{headers}).then(res => {
    loaded = true
    const data: UserDetails = res.data
    user = data

  }).catch(err => {

  })
  return {
    props: { loaded, user}
  }
}
